export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const editIcon = require("../assets/edit_icon.png");
export const logoutIcon = require("../assets/logout_icon.png");
export const termsConditons = require("../assets/terms_icon.png");
export const gotoTerms =  require("../assets/takeme_terms.png");
export const changePassword =  require("../assets/change_password.png");
export const imageUpload = require("../assets/image_upload.png");
export const userProfile = require("../assets/user_profile.png");
export const logoutAlertIcon = require("../assets/logout_alerticon.png");
