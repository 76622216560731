// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import CustomisableUserProfiles from "../../blocks/CustomisableUserProfiles/src/CustomisableUserProfiles";
import CfFacebookPost from "../../blocks/CfFacebookPost/src/CfFacebookPost";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import Documentation from "../../blocks/Documentation/src/Documentation";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import Maps from "../../blocks/maps/src/Maps";
import Download from "../../blocks/Download/src/Download";
import SubscriptionBilling2 from "../../blocks/SubscriptionBilling2/src/SubscriptionBilling2";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import LocationbasedAlerts from "../../blocks/LocationbasedAlerts/src/LocationbasedAlerts";
import RolesPermissions2 from "../../blocks/RolesPermissions2/src/RolesPermissions2";
import InvoiceBilling from "../../blocks/InvoiceBilling/src/InvoiceBilling";
import CameraAccess from "../../blocks/cameraaccess/src/CameraAccess";
import SmsSettings from "../../blocks/SmsSettings/src/SmsSettings";
import QrCodes from "../../blocks/qrcodes/src/QrCodes";
import RequestManagement from "../../blocks/RequestManagement/src/RequestManagement";
import PaymentAdmin2 from "../../blocks/PaymentAdmin2/src/PaymentAdmin2";
import Customform from "../../blocks/customform/src/Customform";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Notifications from "../../blocks/notifications/src/Notifications";
import RealtimeUpdates from "../../blocks/RealtimeUpdates/src/RealtimeUpdates";
import ServiceSpecificSettingsAdmin from "../../blocks/ServiceSpecificSettingsAdmin/src/ServiceSpecificSettingsAdmin";
import Addresses from "../../blocks/addressmanagement/src/Addresses";
import AddAddress from "../../blocks/addressmanagement/src/AddAddress";
import Analytics from "../../blocks/analytics/src/Analytics";
import Customisableusersubscriptions from "../../blocks/customisableusersubscriptions/src/Customisableusersubscriptions";
import SubscriptionDetails from "../../blocks/customisableusersubscriptions/src/SubscriptionDetails";
import ItemAvailability from "../../blocks/ItemAvailability/src/ItemAvailability";
import LinkShare from "../../blocks/LinkShare/src/LinkShare";
import CfCarfaxApi from "../../blocks/CfCarfaxApi/src/CfCarfaxApi";
import Settings5 from "../../blocks/Settings5/src/Settings5";
import InventoryManagement2 from "../../blocks/InventoryManagement2/src/InventoryManagement2";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import ContextTagging from "../../blocks/ContextTagging/src/ContextTagging";
import ContentManagement from "../../blocks/ContentManagement/src/ContentManagement";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import ReviewAndApproval from "../../blocks/ReviewAndApproval/src/ReviewAndApproval";
import UploadMedia2 from "../../blocks/UploadMedia2/src/UploadMedia2";
import TermsAndConditions from "../../blocks/TermsAndConditions/src/TermsAndConditions";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import Location from "../../blocks/location/src/Location";
import MultipageForms2 from "../../blocks/MultipageForms2/src/MultipageForms2";
import Surveys from "../../blocks/Surveys/src/Surveys";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import PremiumPlanComparison from "../../blocks/PremiumPlanComparison/src/PremiumPlanComparison";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import LiveChat2 from "../../blocks/LiveChat2/src/LiveChat2";
import TimeTracker from "../../blocks/TimeTracker/src/TimeTracker";
import CfTwitterPost from "../../blocks/CfTwitterPost/src/CfTwitterPost";
import EmailNotifications from "../../blocks/EmailNotifications/src/EmailNotifications";



const routeMap = {
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
CustomisableUserProfiles:{
 component:CustomisableUserProfiles,
path:"/CustomisableUserProfiles"},
CfFacebookPost:{
 component:CfFacebookPost,
path:"/CfFacebookPost"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
Documentation:{
 component:Documentation,
path:"/Documentation"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},
Maps:{
 component:Maps,
path:"/Maps"},
Download:{
 component:Download,
path:"/Download"},
SubscriptionBilling2:{
 component:SubscriptionBilling2,
path:"/SubscriptionBilling2"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
LocationbasedAlerts:{
 component:LocationbasedAlerts,
path:"/LocationbasedAlerts"},
RolesPermissions2:{
 component:RolesPermissions2,
path:"/RolesPermissions2"},
InvoiceBilling:{
 component:InvoiceBilling,
path:"/InvoiceBilling"},
CameraAccess:{
 component:CameraAccess,
path:"/CameraAccess"},
SmsSettings:{
 component:SmsSettings,
path:"/SmsSettings"},
QrCodes:{
 component:QrCodes,
path:"/QrCodes"},
RequestManagement:{
 component:RequestManagement,
path:"/RequestManagement"},
PaymentAdmin2:{
 component:PaymentAdmin2,
path:"/PaymentAdmin2"},
Customform:{
 component:Customform,
path:"/Customform"},
NavigationMenu:{
 component:NavigationMenu,
path:"/NavigationMenu"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
RealtimeUpdates:{
 component:RealtimeUpdates,
path:"/RealtimeUpdates"},
ServiceSpecificSettingsAdmin:{
 component:ServiceSpecificSettingsAdmin,
path:"/ServiceSpecificSettingsAdmin"},
Addresses:{
 component:Addresses,
path:"/Addresses"},
AddAddress:{
 component:AddAddress,
path:"/AddAddress"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
Customisableusersubscriptions:{
 component:Customisableusersubscriptions,
path:"/Customisableusersubscriptions"},
SubscriptionDetails:{
 component:SubscriptionDetails,
path:"/SubscriptionDetails"},
ItemAvailability:{
 component:ItemAvailability,
path:"/ItemAvailability"},
LinkShare:{
 component:LinkShare,
path:"/LinkShare"},
CfCarfaxApi:{
 component:CfCarfaxApi,
path:"/CfCarfaxApi"},
Settings5:{
 component:Settings5,
path:"/Settings5"},
InventoryManagement2:{
 component:InventoryManagement2,
path:"/InventoryManagement2"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
ContextTagging:{
 component:ContextTagging,
path:"/ContextTagging"},
ContentManagement:{
 component:ContentManagement,
path:"/ContentManagement"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
ReviewAndApproval:{
 component:ReviewAndApproval,
path:"/ReviewAndApproval"},
UploadMedia2:{
 component:UploadMedia2,
path:"/UploadMedia2"},
TermsAndConditions:{
 component:TermsAndConditions,
path:"/TermsAndConditions"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
Location:{
 component:Location,
path:"/Location"},
MultipageForms2:{
 component:MultipageForms2,
path:"/MultipageForms2"},
Surveys:{
 component:Surveys,
path:"/Surveys"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
PremiumPlanComparison:{
 component:PremiumPlanComparison,
path:"/PremiumPlanComparison"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
LiveChat2:{
 component:LiveChat2,
path:"/LiveChat2"},
TimeTracker:{
 component:TimeTracker,
path:"/TimeTracker"},
CfTwitterPost:{
 component:CfTwitterPost,
path:"/CfTwitterPost"},
EmailNotifications:{
 component:EmailNotifications,
path:"/EmailNotifications"},

  Home: {
component:QrCodes,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
